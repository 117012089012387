import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Paper,
  Stack,
  Typography,
  Tab as MUITab,
  Tabs as MUITabs
} from '@mui/material';
import { ReactComponent as plusIcon } from 'assets/svg/plus.svg';
import { ReactComponent as ChevronLeft } from 'assets/svg/chevron-left.svg';
import { ReactComponent as Safe } from 'assets/svg/safe.svg';
import { Button } from 'components/button';
import ShowInfo from 'components/info/showInfo';
import SearchableContainer from 'components/search/searchableContainer';
import { useAppSelector } from 'services/hook';
import { useGetPermissionsQuery } from 'services/api/permissionsApi';
import { OrganizationType } from 'services/enums/organization';
import { PermissionType } from 'services/enums/permissions';
import { VaultMetaData } from 'services/types/vault';
import { OrganizationApiData } from 'services/types/organization';
import VaultSubOrgCard from './cards/vaultSubOrgCard';
import VaultMainOrgCard from './cards/vaultMainOrgCard';
import _ from 'lodash';
import { SvgWrapper } from 'components/svg';
import { useGetVaultMetaDataQuery } from 'services/api/vaultApi';

const tabList = ['central vault'];

const ProductReceiptVaultPage = () => {
  const [currentSubOrgView, setCurrentSubOrgView] = useState(0);
  const [page, setPage] = useState(0);
  const [currentMainOrgIndex, setCurrentMainOrgIndex] = useState(0);

  const { organizationType } = useAppSelector(state => state.auth);
  const { data: permissions } = useGetPermissionsQuery({});
  const {
    data: vaultMetaData,
    isLoading,
    isFetching
  } = useGetVaultMetaDataQuery({});
  const navigate = useNavigate();

  const vaultData = useMemo(() => {
    const emptyVaultData: VaultMetaData[] = [];
    if (vaultMetaData) {
      return vaultMetaData;
    }
    return emptyVaultData;
  }, [vaultMetaData]);

  const mainOrgId =
    vaultData[currentMainOrgIndex]?.organization?.organization_id;
  const centralData = vaultData[currentMainOrgIndex]?.central_vault;

  const renderCentralVaultData = (item: OrganizationApiData) => {
    return (
      <VaultSubOrgCard
        id={item.organization_id}
        name={item.name}
        onClick={() =>
          navigate(
            `/product-receipts/central-vault/${mainOrgId}/${item.organization_id}`
          )
        }
      />
    );
  };

  const renderMainOrgData = (item: VaultMetaData, index?: number) => {
    return (
      <VaultMainOrgCard
        id={item.organization.organization_id}
        name={_.startCase(item.organization.name)}
        logo={item.organization.logo}
        onClick={() => {
          index !== undefined ? setCurrentMainOrgIndex(index) : null;
          switchToNextPage();
        }}
      />
    );
  };

  const switchToNextPage = () => {
    setPage(prev => prev + 1);
  };

  const switchToPrevPage = () => {
    setPage(prev => prev - 1);
  };

  const handleChange = (newTabIndex: number) => {
    setCurrentSubOrgView(newTabIndex);
  };

  const isActiveTab = (tabId: string) => {
    return tabId === tabList[currentSubOrgView];
  };

  const mainOrgName =
    organizationType === OrganizationType.MERCHANT ? 'lender' : 'merchant';
  const subOrgName =
    organizationType === OrganizationType.DISTRIBUTOR
      ? 'lender'
      : 'distributor';

  switch (page) {
    case 0:
      return (
        <Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '1.75rem'
            }}
          >
            <Stack gap=".25rem" width="inherit">
              <Typography
                variant="h6Bold"
                color="#475467"
                sx={{ width: 'auto' }}
              >
                All {_.capitalize(mainOrgName)}s
              </Typography>
              <Typography
                variant="bodyMediumMedium"
                color="#98A2B3"
                sx={{ width: 'auto' }}
              >
                View all {mainOrgName}s associated with your organization
              </Typography>
            </Stack>

            {organizationType === OrganizationType.MERCHANT && (
              <Button
                color="primary"
                size="md"
                icon={plusIcon}
                iconPosition="start"
                text="Create Product Receipt"
                onClick={() => {
                  if (
                    !permissions ||
                    !permissions.includes(PermissionType.PRODUCT_RECEIPT_UPDATE)
                  ) {
                    ShowInfo({
                      message: 'Cannot create a product receipt',
                      subText: 'You do not have permissions to do this!',
                      type: 'error',
                      componentType: 'toast',
                      hideProgressBar: false
                    });
                    return;
                  }
                  navigate('/product-receipts/create');
                }}
              />
            )}
          </Box>

          <Paper
            elevation={0}
            sx={{
              padding: '1.25rem',
              borderRadius: '0.75rem',
              backgroundColor: '#FFF',
              position: 'relative',
              height: '100%',
              border: '.0625rem solid #F5F6F7'
            }}
          >
            <SearchableContainer<VaultMetaData>
              searchPlaceholderText={`Search with ${mainOrgName} name or ID`}
              data={vaultData}
              renderDataList={renderMainOrgData}
              searchField={[
                'organization.name',
                'organization.organization_id'
              ]}
              containerStyles={{
                maxHeight: '60svh',
                overflowY: 'auto',
                width: '100%',
                borderTop: 1,
                borderColor: '#F5F6F7'
              }}
              listStylesOverride={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                flexWrap: 'wrap',
                gap: {
                  md: '2rem 1.25rem',
                  xl: '3rem'
                },
                padding: {
                  md: '1.25rem 0.75rem',
                  xl: '2rem 2.5rem'
                },
                borderRadius: '0.75rem',
                backgroundColor: '#FCFCFD',
                mt: '1.5rem'
              }}
              loading={isFetching || isLoading}
              enableEmptyData
            />
          </Paper>
        </Box>
      );
    case 1:
      return (
        <Box sx={{ height: '100%' }}>
          <Box mb="1rem">
            <Button
              color="grey"
              transparent
              icon={ChevronLeft}
              iconPosition="start"
              size="sm"
              onClick={() => switchToPrevPage()}
              text="Back to Product Receipt Vault"
              width="auto"
              styleOverrides={{
                border: 'none'
              }}
            />
          </Box>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <MUITabs
              orientation="horizontal"
              value={currentSubOrgView}
              onChange={(e, value) => handleChange(value)}
              sx={{
                borderBottom: 1,
                borderColor: '#F5F6F7',
                marginBottom: '1.75rem',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <MUITab
                label={
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '.5rem',
                      alignItems: 'flex-start',
                      width: '100%'
                    }}
                  >
                    <SvgWrapper
                      icon={Safe}
                      width="1.25rem"
                      height="1.25rem"
                      styleOverrides={{
                        fill: isActiveTab('central vault')
                          ? '#3E7DF8'
                          : '#D0D5DD'
                      }}
                    />
                    <Typography variant="bodyMediumMedium">Vault</Typography>
                  </Box>
                }
              />
            </MUITabs>
            <Box
              role="tabpanel"
              hidden={!isActiveTab('central vault')}
              sx={{ width: '100%' }}
            >
              <Paper
                elevation={0}
                sx={{
                  padding: '1.25rem',
                  borderRadius: '0.75rem',
                  backgroundColor: '#FFF',
                  position: 'relative',
                  height: '100%',
                  border: '.0625rem solid #F5F6F7'
                }}
              >
                <SearchableContainer<OrganizationApiData>
                  searchPlaceholderText={`Search with ${subOrgName} name`}
                  data={centralData}
                  renderDataList={renderCentralVaultData}
                  searchField={['name']}
                  containerStyles={{
                    maxHeight: '60svh',
                    overflowY: 'auto',
                    width: '100%',
                    borderTop: 1,
                    borderColor: '#F5F6F7'
                  }}
                  listStylesOverride={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    flexWrap: 'wrap',
                    gap: {
                      md: '2rem 1.25rem',
                      xl: '2rem'
                    },
                    padding: {
                      md: '1.25rem 0.75rem',
                      xl: '2rem 2.5rem'
                    },
                    borderRadius: '0.75rem',
                    backgroundColor: '#FCFCFD',
                    mt: '1.5rem'
                  }}
                  title={'Vault'}
                  subtitle={`See all ${subOrgName}s with unassigned ATCs under this merchant.`}
                  loading={isFetching || isLoading}
                  enableEmptyData
                />
              </Paper>
            </Box>
          </Box>
        </Box>
      );
    default:
      return null;
  }
};

export default ProductReceiptVaultPage;
