import React, { useEffect } from 'react';
import { theme } from 'themes/theme';
import { Box, Typography, Paper, Stack, Avatar } from '@mui/material';
import image from 'assets/png/image.png';
import { FormFileInput, FormInput } from 'components/form';
import { Button } from 'components/button';
import { useUpdateUserMutation } from 'services/api/userApi';
import { useForm, SubmitHandler } from 'react-hook-form';
import ShowInfo from 'components/info/showInfo';

type FormValues = {
  firstname: string;
  lastname: string;
  profile_picture?: unknown;
};

const ProfileSettings = () => {
  const [updateUser, { data, isSuccess, isError, error }] =
    useUpdateUserMutation();
  const { handleSubmit, control, setValue } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      firstname: data?.firstname,
      lastname: data?.lastname
    }
  });

  const onSubmit: SubmitHandler<FormValues> = async data => {
    await updateUser({
      firstname: data.firstname,
      lastname: data.lastname
    });
  };

  interface MyError {
    data:
      | string
      | {
          email: string[];
        };
    status?: number;
  }

  useEffect(() => {
    if (isSuccess) {
      ShowInfo({
        message: 'Profile updated!',
        subText: '',
        type: 'success',
        componentType: 'toast',
        hideProgressBar: false
      });
    }
    if (error !== undefined) {
      const myError = error as MyError;
      if (
        typeof myError.data === 'object' &&
        Object.values(myError.data).length > 0
      ) {
        ShowInfo({
          message: `${Object.values(myError.data)[0].join(', ')}`,
          subText: '',
          type: 'error',
          componentType: 'toast',
          hideProgressBar: false
        });
      } else if (typeof myError.data === 'string') {
        ShowInfo({
          message: `${myError.data}`,
          subText: '',
          type: 'error',
          componentType: 'toast',
          hideProgressBar: false
        });
      } else {
        ShowInfo({
          message: 'An error occurred',
          subText: '',
          type: 'error',
          componentType: 'toast',
          hideProgressBar: false
        });
      }
    }
  }, [isSuccess, isError]);

  return (
    <Paper
      elevation={0}
      sx={{
        backgroundColor: `${theme.palette.common.white}`,
        border: `0.063rem solid ${theme.palette.common.cardBorder}`,
        borderRadius: '0.75rem',
        gap: '1.5rem',
        padding: '1.5rem',
        width: '100%'
      }}
    >
      <Box width="inherit">
        <Box
          width="inherit"
          sx={{
            alignItems: 'flex-start',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Typography
            variant="bodyLargeSemibold"
            sx={{
              color: `${theme.palette.common.heading}`
            }}
          >
            Profile Settings
          </Typography>
          <Typography
            variant="bodyMediumRegular"
            sx={{
              color: `${theme.palette.common.labelColor}`
            }}
          >
            Change and update your profile settings here
          </Typography>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              alignSelf: 'stretch',
              padding: '1rem 2rem',
              backgroundColor: `${theme.palette.common.cardBorder}`,
              borderRadius: '0.75rem',
              gap: '1rem',
              marginTop: '1.5rem'
            }}
          >
            <Avatar
              src={image}
              sx={{
                width: '3.5rem',
                height: '3.5rem',
                borderRadius: '12.5rem'
              }}
            />
            <Stack direction="row" display="flex" alignItems="center">
              <FormFileInput<FormValues>
                control={control}
                name="profile_picture"
                label={
                  <Typography
                    variant="bodyMediumSemibold"
                    sx={{
                      color: `${theme.palette.common.subheading}`
                    }}
                  >
                    Change Image
                  </Typography>
                }
                setFile={(file: File | null) =>
                  setValue('profile_picture', file)
                }
                supportedFiles="image/png,image/jpeg,image/jpg"
                fileInputButtonStyle={{
                  borderRadius: '0.5rem',
                  padding: '0.5rem 0.75rem',
                  gap: '0.25rem',
                  mr: '1.25rem',
                  border: '0.094rem solid #F2F4F7',
                  backgroundColor: `${theme.palette.common.white}`
                }}
              />

              <Button
                color="grey"
                transparent
                size="md"
                text="Delete Image"
                styleOverrides={{
                  padding: '0.75rem 1rem'
                }}
                submit
              />
            </Stack>
            <Typography
              variant="captionSmall"
              sx={{
                color: `${theme.palette.common.textColor}`
              }}
            >
              IMG must be 256 * 256 - Max 2MB
            </Typography>
          </Box>

          <Box width="inherit" sx={{ mt: '1.5rem' }}>
            <Stack width="100%" gap=".75rem" direction="row">
              <FormInput<FormValues>
                control={control}
                name="firstname"
                label="FIRST NAME  "
              />

              <FormInput<FormValues>
                control={control}
                name="lastname"
                label="LAST NAME"
              />
            </Stack>
          </Box>
          <Stack direction="row" display="flex" justifyContent="flex-end">
            <Button
              color="grey"
              transparent
              size="md"
              text="Cancel"
              styleOverrides={{
                padding: '0.5rem 0.75rem'
              }}
            />

            <Button
              color="primary"
              size="md"
              text="Save Changes"
              styleOverrides={{
                padding: '0.5rem 0.75rem',
                ml: '1rem'
              }}
              submit
            />
          </Stack>
        </form>
      </Box>
    </Paper>
  );
};

export default ProfileSettings;
