import React from 'react';
import { Box, Grid } from '@mui/material';
import CreateProductReceiptTopNav from '../nav/topNav';
import CreateProductReceiptBottomNav from '../nav/bottomNav';

const CreateProductReceiptLayout: React.FC<React.PropsWithChildren> = ({
  children
}) => {
  return (
    <Grid
      container
      sx={{ height: 'inherit', overflow: 'hidden', width: 'inherit' }}
    >
      <Grid item xs={12}>
        <Box
          sx={{
            position: 'sticky',
            width: '100%',
            top: 0,
            zIndex: 2
          }}
        >
          <CreateProductReceiptTopNav />
        </Box>

        <Box
          sx={{
            px: {
              md: '3rem',
              lg: '6rem',
              xl: '10rem'
            },
            paddingY: {
              md: '2rem',
              lg: '2.5rem',
              xl: '5rem'
            },
            height: '100%',
            maxHeight: '93.7vh',
            overflowY: 'auto',
            background: '#FCFDFF'
          }}
        >
          {children}
        </Box>

        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            width: '100%',
            zIndex: 2
          }}
        >
          <CreateProductReceiptBottomNav />
        </Box>
      </Grid>
    </Grid>
  );
};

export default CreateProductReceiptLayout;
