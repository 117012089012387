import React from 'react';
import { theme } from 'themes/theme';
import {
  Avatar,
  Box,
  Stack,
  Toolbar,
  Typography,
  Divider,
  Button as MUIButton
} from '@mui/material';
import chevronLeft from 'assets/svg/chevronLeft.svg';
import { ReactComponent as HelpSolid } from 'assets/svg/helpSolid.svg';
import addUserSolid from 'assets/svg/addUserSolid.svg';
import SettingsBreadcrumb from './settingsBreadcrumb';
import { SvgWrapper } from 'components/svg';

const SettingsNavbar = () => {
  return (
    <Toolbar
      sx={{
        width: 'inherit',
        backgroundColor: `${theme.palette.common.appBackground}`,
        padding: '0.75rem 3rem',
        height: '4.25rem',
        borderBottom: '1px solid #F5F6F7'
      }}
    >
      <Stack
        width="100%"
        direction="row"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack
          display="flex"
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Avatar src={chevronLeft} sx={{ width: '20px', height: '20px' }} />

          <SettingsBreadcrumb />
        </Stack>

        <Typography
          variant="bodyLargeSemibold"
          color={`${theme.palette.common.subheading}`}
        >
          Settings
        </Typography>

        <Stack
          direction="row"
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          sx={{
            padding: '0.75rem 1rem',
            gap: '1rem'
          }}
        >
          <SvgWrapper
            icon={HelpSolid}
            width="1.25rem"
            height="1.25rem"
            styleOverrides={{
              fill: '#98A2B3',
              stroke: '#fff',
              mr: '.5rem'
            }}
          />

          <Divider
            orientation="vertical"
            variant="middle"
            sx={{
              width: '0.063rem',
              height: '2.25rem',
              borderRadius: '0.125rem',
              borderLeft: '0.0625rem solid #F2F4F7',
              borderRight: '0.0625rem solid #F2F4F7',
              mr: '0.5rem'
            }}
          />

          <MUIButton
            variant="outlined"
            sx={{
              border: `1px solid ${theme.palette.common.btnColor}`,
              borderRadius: '0.5rem',
              color: `${theme.palette.common.btnColor}`,
              backgroundColor: `${theme.palette.common.white}`,
              padding: '0.5rem 0.75rem',
              fontSize: '0.875rem',
              fontWeight: 600,
              lineHeight: '1.26rem',
              letterSpacing: '-0.01313rem'
            }}
            startIcon={<Box component={'img'} src={addUserSolid} />}
          >
            Invite User
          </MUIButton>
        </Stack>
      </Stack>
    </Toolbar>
  );
};

export default SettingsNavbar;
