import React, { useState } from 'react';
import { Box, Divider, Paper, Stack, Typography } from '@mui/material';
import { ReactComponent as ChevronDown } from 'assets/svg/chevron-down.svg';
import { ReactComponent as ChevronUp } from 'assets/svg/chevron-up.svg';
import { ReactComponent as FormSolid } from 'assets/svg/form.svg';
import { ReactComponent as Truck } from 'assets/svg/truck.svg';
import { ReactComponent as ZapIcon } from 'assets/svg/zap-fast.svg';
import { SvgWrapper } from 'components/svg';
import IndicatorCard from './indicatorCard';
import Chart from './chart/lineChart';
import { ProductReceiptApiData } from 'services/types/productReceipt';

const ProductReceiptAnalytics = ({
  productReceipt
}: {
  productReceipt: ProductReceiptApiData;
}) => {
  const [showChart, setShowChart] = useState(false);
  return (
    <Box
      sx={{
        width: 'inherit',
        maxWidth: '80rem',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Paper
        elevation={0}
        sx={{
          padding: '1.25rem .75rem',
          borderRadius: '0.75rem',
          backgroundColor: '#FFF',
          border: '0.0625rem solid #F5F6F7',
          width: 'inherit',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-around',
          height: '8.125rem'
        }}
      >
        <IndicatorCard
          title="Workflow Approval Time"
          time={
            productReceipt.branch_assignment_workflow
              ? productReceipt.branch_assignment_workflow.approval_time * 1000
              : undefined
          }
          image={ZapIcon}
          iconFill="#1D3B7B40"
          iconStroke="#1D3B7B40"
        />

        <Divider
          orientation="vertical"
          variant="middle"
          sx={{
            width: '0.0625rem',
            height: '4.3rem',
            margin: '0rem .5rem',
            backgroundColor: '#F2F4F7'
          }}
        />

        <IndicatorCard
          title="Total Holding Period"
          time={productReceipt.total_holding_period * 1000}
          image={FormSolid}
          iconFill="#1D3B7B40"
        />

        <Divider
          orientation="vertical"
          variant="middle"
          sx={{
            width: '0.0625rem',
            height: '4.3rem',
            margin: '0rem .5rem',
            backgroundColor: '#F2F4F7'
          }}
        />

        <IndicatorCard
          title="Branch Holding Period"
          time={productReceipt.branch_holding_period * 1000}
          image={Truck}
          iconFill="#1D3B7B40"
        />
      </Paper>

      <Paper
        elevation={0}
        sx={{
          margin: '1rem 0rem',
          padding: '.75rem',
          borderRadius: '0.75rem',
          backgroundColor: '#FFF',
          border: '0.0625rem solid #F5F6F7',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer'
        }}
        onClick={() => setShowChart(!showChart)}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '.25rem',
            justifyContent: 'center'
          }}
        >
          <Typography variant="bodyMediumSemibold" color="#475467">
            {showChart ? 'Show Less' : 'Show More'}
          </Typography>
          <SvgWrapper
            icon={showChart ? ChevronUp : ChevronDown}
            width={showChart ? '1.25rem' : '1rem'}
            height={showChart ? '1.25rem' : '1rem'}
            styleOverrides={{
              stroke: '#475467',
              fill: 'none',
              mb: 0.25
            }}
          />
        </Box>
      </Paper>

      {showChart && (
        <Stack
          direction="row"
          justifyContent="space-between"
          width="100%"
          height="20rem"
          sx={{
            gap: {
              md: '.5rem',
              lg: '1rem'
            }
          }}
        >
          <Box
            sx={{
              width: {
                md: '75%',
                xl: '80%'
              },
              padding: '.75rem',
              backgroundColor: '#FFF',
              border: '.6rem solid #F5F6FA',
              borderRadius: '.75rem'
            }}
          >
            <Chart />
          </Box>
          <Stack
            direction="column"
            justifyContent="space-between"
            height="100%"
          >
            <Stack
              direction="column"
              justifyContent="space-around"
              alignItems="flex-start"
              width="inherit"
              gap=".75rem"
              sx={{
                padding: '1rem .75rem',
                backgroundColor: '#FFF',
                border: '1px solid #F0F2F7',
                borderRadius: '.75rem'
              }}
            >
              <Typography variant="bodyMediumMedium" color="#667085">
                Balance Due
              </Typography>
              <Typography variant="h7Semibold" color="#475467">
                N 200,000,000
              </Typography>
            </Stack>
            <Stack
              direction="column"
              justifyContent="space-around"
              alignItems="flex-start"
              width="inherit"
              gap=".75rem"
              sx={{
                padding: '1rem .75rem',
                backgroundColor: '#FFF',
                border: '1px solid #F0F2F7',
                borderRadius: '.75rem'
              }}
            >
              <Typography variant="bodyMediumMedium" color="#667085">
                Last Repayment Date
              </Typography>
              <Typography variant="h7Semibold" color="#475467">
                22nd Aug, 2024
              </Typography>
            </Stack>
            <Stack
              direction="column"
              justifyContent="space-around"
              alignItems="flex-start"
              width="100%"
              gap=".5rem"
              sx={{
                padding: '1rem .75rem',
                backgroundColor: '#FFF',
                borderRadius: '.75rem',
                border: '1px solid #F0F2F7'
              }}
            >
              <Typography variant="bodyMediumMedium" color="#667085">
                Estimated Repayment Time
              </Typography>
              <Typography variant="h7Semibold" color="#475467">
                20 days
              </Typography>
              <Typography variant="captionXSmall" color="#98A2B3">
                90% Accuracy
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      )}
    </Box>
  );
};

export default ProductReceiptAnalytics;
