import React, { useContext } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { ReactComponent as UploadIcon } from 'assets/svg/upload2.svg';
import { ReactComponent as Question } from 'assets/svg/question.svg';
import { Button } from 'components/button';
import { SvgWrapper } from 'components/svg';
import FileUpload from 'components/common/fileUpload';
import { Resolver, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { bulkUploadFormValidator } from '../../branchesValidators';
import { BranchesContext } from '../../branchesContext';

type FormValues = {
  file: File;
};

const BulkFileUpload = () => {
  const { handleNextStep, goToStep, setUploadedFile } =
    useContext(BranchesContext);

  const {
    handleSubmit,
    formState: { isValid }
  } = useForm<FormValues>({
    mode: 'onTouched',
    resolver: yupResolver(
      bulkUploadFormValidator
    ) as unknown as Resolver<FormValues>
  });

  const onSubmit: SubmitHandler<FormValues> = data => {
    handleNextStep({
      file: data.file
    });
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '1.25rem',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
          borderBottom: '2px solid #F5F6F7',
          width: '24.5rem'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '2.25rem',
            width: '2.25rem',
            borderRadius: '6.25rem',
            backgroundColor: '#E5EEFF',
            padding: '0.5rem',
            boxShadow: '0px 0px 0px 4px rgba(200, 219, 255, 0.20)',
            marginBottom: '1rem'
          }}
        >
          <SvgWrapper
            icon={UploadIcon}
            width="1.25rem"
            height="1.25rem"
            styleOverrides={{
              opacity: '0.8rem',
              fill: '#C1D6FF'
            }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            justifyContent: 'center'
          }}
        >
          <Typography variant="h7Semibold" color="#344054">
            Bulk Upload
          </Typography>
          <Typography variant="bodyMediumRegular" color="#667085">
            Invite new users or add team members to the Lagos-ojumota branch
          </Typography>
        </Box>
      </Box>

      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Box
          sx={{
            padding: '1rem 1.5rem',
            backgroundColor: '#FCFCFC',
            width: '24.5rem'
          }}
        >
          <Box
            sx={{
              padding: '1.25rem 0.75rem',
              backgroundColor: '#F9F9FA',
              borderRadius: '0.5rem',
              border: '0.125rem solid #F5F6F7'
            }}
          >
            <FileUpload
              onFileUpload={files => setUploadedFile(files[0])}
              maxFiles={1}
              maxSize={1024 * 1000}
              supportedFiles={{
                'text/csv': ['.csv'],
                'application/vnd.ms-excel': ['.xls'],
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                  ['.xlsx']
              }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '1rem .75rem',
            margin: ' 0.5rem .75rem',
            borderBottom: '1px solid #F5F6F7',
            width: '24.5rem'
          }}
        >
          <Stack
            direction={'row'}
            gap={'.75rem'}
            alignItems={'baseline'}
            justifyContent={'center'}
            width={'inherit'}
          >
            <Box
              sx={{
                position: 'relative',
                top: '.3rem'
              }}
            >
              <SvgWrapper
                icon={Question}
                width="1rem"
                height="1rem"
                color="#C1D6FF"
              />
            </Box>

            <Typography
              variant="bodySmallRegular"
              sx={{
                color: '#667085'
              }}
            >
              Data tables uploaded must have{' '}
              <span
                style={{
                  fontWeight: 600,
                  color: '#344054'
                }}
              >
                email addresses.
              </span>{' '}
              Make sure emails in this document have already been invited to
              your organization
            </Typography>
          </Stack>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            borderTop: '1px solid #F5F6F7',
            padding: '1.25rem 1.5rem',
            marginBottom: '0.25rem',
            width: '24.5rem'
          }}
        >
          <Button
            color="grey"
            transparent
            size="lg"
            onClick={() => {
              goToStep(1);
            }}
            text="Back"
            width="48%"
          />
          <Button
            submit
            disabled={!isValid}
            color="primary"
            size="lg"
            text="Invite all"
            width="48%"
          />
        </Box>
      </form>
    </>
  );
};

export default BulkFileUpload;
