import React from 'react';
import ellipse from 'assets/svg/ellipse.svg';
import { ReactComponent as Trash } from 'assets/svg/trashSolid.svg';
import { ReactComponent as Download } from 'assets/svg/download.svg';
import { theme } from 'themes/theme';
import { Box, Typography, Stack } from '@mui/material';
import { formattedDate, kbFormatter } from 'utilities/helperFunc/formatter';
import { SvgWrapper } from 'components/svg';
import _ from 'lodash';

interface FileViewProps {
  preview: File;
  removeFile: () => void;
}

const FileView = ({ preview, removeFile }: FileViewProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: '1.5rem',
        maxHeight: '3.5rem'
      }}
    >
      <Stack
        direction="row"
        gap=".75rem"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          sx={{
            borderRadius: '0.5rem 0rem 0rem 0.5rem',
            background: '#0D5EBA',
            padding: '0.875rem 0.625rem',
            color: 'white'
          }}
        >
          File
        </Box>

        <Stack direction="column">
          <Typography
            variant="bodyLargeSemibold"
            sx={{
              color: `${theme.palette.common.heading}`,
              maxWidth: '21rem'
            }}
          >
            {_.truncate(preview.name, { length: 20, omission: '...' })}
          </Typography>
          <Typography
            variant="bodyMediumMedium"
            sx={{
              color: `${theme.palette.common.textColor}`,
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              width: 'auto'
            }}
          >
            <span style={{ fontWeight: 400 }}>
              {formattedDate(preview.lastModified, 'Do MMM, YYYY hh:ma')}
            </span>
            <Box
              component="img"
              src={ellipse}
              sx={{
                margin: '0rem .5rem'
              }}
            />
            <span>{kbFormatter(`${preview.size}`)}</span>
          </Typography>
        </Stack>
      </Stack>

      <Stack direction="row" gap=".75rem">
        <Box
          onClick={() => removeFile()}
          component="span"
          sx={{ cursor: 'pointer' }}
        >
          <SvgWrapper
            icon={Trash}
            width="1.5rem"
            height="1.5rem"
            styleOverrides={{
              opacity: 0.8,
              fill: '#475467'
            }}
          />
        </Box>

        <Box component="span" sx={{ cursor: 'pointer' }}>
          <SvgWrapper
            icon={Download}
            width="1.5rem"
            height="1.5rem"
            styleOverrides={{
              opacity: 0.8,
              fill: 'none',
              stroke: '#98A2B3'
            }}
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default FileView;
