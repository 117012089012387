import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import { ReactComponent as UploadIcon } from 'assets/svg/upload2.svg';
import { Button } from 'components/button';
import { SvgWrapper } from 'components/svg';
import { TeamsContext } from '../../teamsContext';
import { FormSelect, FormSelectOption } from 'components/form';
import { Resolver, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { createTeamManuallyValidator } from '../../teamsValidators';
import ShowInfo from 'components/info/showInfo';

type FormValues = {
  members: string[];
};

const sampleOptions: FormSelectOption[] = [
  {
    title: 'Evelyn Jack',
    subtitle: 'Operations Lead',
    value: 'EvelynJack@gmail.com'
  },
  {
    title: 'Chika Jack',
    subtitle: 'Operations Junior',
    value: 'ChikaJack@gmail.com'
  }
];

const ManualTeamUpload = () => {
  const { data, handleNextStep, handlePrevStep, setCreateTeamModalOpen } =
    useContext(TeamsContext);

  const {
    control,
    handleSubmit,
    formState: { isValid }
  } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      members: data.members
    },
    resolver: yupResolver(
      createTeamManuallyValidator
    ) as unknown as Resolver<FormValues>
  });

  const onSubmit: SubmitHandler<FormValues> = data => {
    handleNextStep({
      members: data.members
    });
    ShowInfo({
      message: 'Users have been added to the Lagos Ijumota-branch',
      subText: '',
      type: 'success',
      componentType: 'toast',
      hideProgressBar: false
    });
    setCreateTeamModalOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '1.25rem',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
          borderBottom: '2px solid #F5F6F7',
          width: '24.5rem'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '2.25rem',
            width: '2.25rem',
            borderRadius: '6.25rem',
            backgroundColor: '#E5EEFF',
            padding: '0.5rem',
            boxShadow: '0px 0px 0px 4px rgba(200, 219, 255, 0.20)',
            marginBottom: '1rem'
          }}
        >
          <SvgWrapper
            icon={UploadIcon}
            width="1.25rem"
            height="1.25rem"
            styleOverrides={{
              opacity: '0.8rem',
              fill: '#C1D6FF'
            }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            justifyContent: 'center'
          }}
        >
          <Typography variant="h7Semibold" color="#344054">
            Invite Users
          </Typography>
          <Typography variant="bodyMediumRegular" color="#667085">
            Invite new users or add team members to the Lagos-ojumota branch
          </Typography>
        </Box>
      </Box>

      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Box
          sx={{
            padding: '1rem 1.5rem',
            backgroundColor: '#FCFCFC',
            width: '24.5rem'
          }}
        >
          <Box>
            <FormSelect<FormValues>
              options={sampleOptions}
              control={control}
              label="Email"
              name="members"
              renderSelected={value => value}
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            borderTop: '1px solid #F5F6F7',
            padding: '1.25rem 1.5rem',
            marginBottom: '0.25rem',
            width: '24.5rem'
          }}
        >
          <Button
            color="grey"
            transparent
            size="lg"
            onClick={() => {
              handlePrevStep();
            }}
            text="Back"
            width="48%"
          />
          <Button
            submit
            disabled={!isValid}
            color="primary"
            size="lg"
            text="Send Invites"
            width="48%"
          />
        </Box>
      </form>
    </>
  );
};

export default ManualTeamUpload;
