import React, { useState } from 'react';
import { Box, Paper, Stack, Typography } from '@mui/material';
import marble from 'assets/png/marble.png';
import { ReactComponent as ChevronRight } from 'assets/svg/chevron-right.svg';
import { Chip } from 'components/chip';
import { SvgWrapper } from 'components/svg';
import _ from 'lodash';

interface CardDataProps {
  name: string;
  id: string;
  onClick?: () => void;
}

const VaultSubOrgCard = ({ name, id, onClick }: CardDataProps) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <Paper
      elevation={0}
      sx={{
        padding: '.5rem',
        height: 'auto',
        width: 'auto',
        borderRadius: '.75rem',
        border: '1px solid #F5F6F7',
        cursor: 'pointer',
        color: '#667085',
        boxShadow: '0px 1.5px 4px -1px rgba(20, 28, 40, 0.05)',
        '&:hover': {
          boxShadow: '4px 4px 6px 3px rgba(124, 138, 169, 0.12)',
          color: '#475467'
        }
      }}
      onClick={onClick}
    >
      <Stack
        direction="column"
        gap=".5rem"
        alignItems="flex-start"
        sx={{
          height: '100%',
          width: 'auto',
          padding: '.75rem .5rem',
          backgroundImage: `url(${marble})`,
          objectFit: 'cover'
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: { md: '11rem', xl: '13rem' }
          }}
          onMouseOver={() => setIsActive(!isActive)}
        >
          <Typography variant="bodyMediumSemibold" color="inherit">
            {_.truncate(`${_.startCase(name)}`, {
              length: 26,
              omission: '...'
            })}
          </Typography>

          {isActive && (
            <Box sx={{ display: 'flex' }}>
              <SvgWrapper
                icon={ChevronRight}
                width="1rem"
                height="1rem"
                styleOverrides={{ stroke: '#98A2B3', fill: 'none' }}
              />
            </Box>
          )}
        </Stack>

        <Box>
          <Chip label={`ID / ${id}`} size="xs" color="inherit" />
        </Box>
      </Stack>
    </Paper>
  );
};

export default VaultSubOrgCard;
