import React from 'react';
import { Typography, Divider as MUIDivider } from '@mui/material';
import { theme } from 'themes/theme';

interface DividerProps {
  fontWeight?: number;
  mb?: string;
  mt?: string;
  fontSize?: string;
  lineHeight?: string;
  width?: string;
}

const Divider = ({
  fontWeight,
  mb,
  mt,
  fontSize,
  lineHeight,
  width
}: DividerProps) => {
  return (
    <MUIDivider
      sx={{
        color: `${theme.palette.common.dividerColor}`,
        mb: mb,
        mt: mt,
        width: width
      }}
    >
      <Typography
        variant="bodyLargeSemibold"
        sx={{
          fontWeight: fontWeight,
          color: `${theme.palette.common.textColor}`,
          fontSize: fontSize,
          lineheight: lineHeight
        }}
      >
        OR
      </Typography>
    </MUIDivider>
  );
};

export default Divider;
