import React, { useContext } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import ellipse from 'assets/svg/ellipse.svg';
import { ReactComponent as UploadIcon } from 'assets/svg/upload2.svg';
import { Button } from 'components/button';
import { TeamsContext } from '../../teamsContext';
import { TabContainer } from 'components/tabs';
import MembersTable from '../../../common/membersTable';
import { theme } from 'themes/theme';
import { formattedDate, kbFormatter } from 'utilities/helperFunc/formatter';
import NotInOrgTable from '../../../common/notInOrgTable';
import { SvgWrapper } from 'components/svg';

const tabs = [
  {
    labelText: 'Members',
    content: <MembersTable />
  },
  {
    labelText: 'Not in organization',
    content: <NotInOrgTable />
  }
];

const ViewBulkTeamUpload = () => {
  const { uploadedFile, setUploadedFile } = useContext(TeamsContext);

  return (
    <Stack width={'34.5rem'}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '1.25rem',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
          borderBottom: '2px solid #F5F6F7'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '2.25rem',
            width: '2.25rem',
            borderRadius: '6.25rem',
            backgroundColor: '#E5EEFF',
            padding: '0.5rem',
            boxShadow: '0px 0px 0px 4px rgba(200, 219, 255, 0.20)',
            marginBottom: '1rem'
          }}
        >
          <SvgWrapper
            icon={UploadIcon}
            width="1.25rem"
            height="1.25rem"
            styleOverrides={{
              opacity: '0.8rem',
              fill: '#C1D6FF'
            }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            justifyContent: 'center'
          }}
        >
          <Typography variant="h7Semibold" color="#344054">
            Invite Users
          </Typography>
          <Typography variant="bodyMediumRegular" color="#667085">
            This shows all the users in your CSV file
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          padding: '0rem 1.25rem',
          mb: '0.5rem'
        }}
      >
        <Box
          sx={{
            padding: '1rem 1.25rem',
            backgroundColor: '#F6F8FD',
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Stack
            direction={'row'}
            gap={'.75rem'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <Box
              sx={{
                borderRadius: '0.5rem 0rem 0rem 0.5rem',
                background: '#0D5EBA',
                padding: '0.875rem 0.625rem',
                color: 'white'
              }}
            >
              PDF
            </Box>

            <Stack direction={'column'}>
              <Typography
                variant="bodyLargeSemibold"
                sx={{
                  color: `${theme.palette.common.heading}`,

                  maxWidth: '21rem'
                }}
              >
                {uploadedFile !== null && uploadedFile.name}
              </Typography>
              <Typography
                variant="bodyMediumMedium"
                sx={{
                  color: `${theme.palette.common.textColor}`,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '14rem'
                }}
              >
                <span style={{ fontWeight: 400 }}>
                  {uploadedFile !== null &&
                    formattedDate(
                      uploadedFile.lastModified,
                      'do MMM, yyyy hh:mmaaa'
                    )}
                </span>
                <Box component={'img'} src={ellipse} />
                <span>
                  {kbFormatter(`${uploadedFile !== null && uploadedFile.size}`)}
                </span>
              </Typography>
            </Stack>
          </Stack>

          <Stack direction={'row'}>
            <Button
              color="grey"
              transparent
              size="sm"
              text="Change file"
              onClick={() => {
                setUploadedFile(null);
              }}
            />
          </Stack>
        </Box>
      </Box>
      <Box
        sx={{
          padding: '0rem 1.5rem',
          backgroundColor: '#FCFCFC',
          width: 'inherit',
          maxHeight: '23rem',
          overflowY: 'scroll'
        }}
      >
        <TabContainer tabs={tabs} />
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          borderTop: '1px solid #F5F6F7',
          padding: '1.25rem 1.5rem',
          marginBottom: '0.25rem'
        }}
      >
        <Button
          color="grey"
          transparent
          size="lg"
          onClick={() => {
            setUploadedFile(null);
          }}
          text="Back"
          width="48%"
        />
        <Button
          submit
          disabled={uploadedFile === null}
          color="primary"
          size="lg"
          text="Invite all"
          width="48%"
        />
      </Box>
    </Stack>
  );
};

export default ViewBulkTeamUpload;
