import React from 'react';
import {
  Avatar,
  Stack,
  Toolbar,
  Typography,
  Button as MUIButton
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import chevronLeft from 'assets/svg/chevronLeft.svg';
import { Button } from 'components/button';

const CreateProductReceiptBottomNav = () => {
  const navigate = useNavigate();

  return (
    <Toolbar
      sx={{
        width: 'inherit',
        padding: '0.75rem 3rem',
        height: '100%',
        borderTop: '0.063rem solid #F5F6F7',
        background: '#fff'
      }}
    >
      <Stack
        width="100%"
        direction="row"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <MUIButton
          variant="text"
          startIcon={
            <Avatar
              src={chevronLeft}
              sx={{ width: '1.25rem', height: '1.25rem' }}
            />
          }
          onClick={() => navigate('/product-receipts')}
        >
          <Typography variant="bodyMediumSemibold" color="#344054">
            Exit PRC creation
          </Typography>
        </MUIButton>

        <Stack direction="row" gap=".75rem">
          <Button
            color="primary"
            size="md"
            text="Next"
            styleOverrides={{
              width: '10rem',
              padding: '.75rem',
              borderRadius: '.5rem'
            }}
          />
        </Stack>
      </Stack>
    </Toolbar>
  );
};

export default CreateProductReceiptBottomNav;
