import React from 'react';
import { Box, Divider, Paper, Stack, Typography } from '@mui/material';
import { ReactComponent as FileLocked } from 'assets/svg/file-locked.svg';
import { SvgWrapper } from 'components/svg';
import { ProductReceiptApiData } from 'services/types/productReceipt';
import { formattedNumber } from 'utilities/helperFunc/formatter';

const ProductReceiptDetails = ({
  productReceipt
}: {
  productReceipt: ProductReceiptApiData;
}) => {
  return (
    <Box
      sx={{
        width: 'inherit',
        maxWidth: '80rem',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <Paper
        elevation={0}
        sx={{
          padding: '1.25rem',
          gap: '1rem',
          width: 'auto'
        }}
      >
        <Stack
          width="30rem"
          direction="row"
          display="flex"
          justifyContent="flex-start"
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '2.25rem',
              width: '2.25rem',
              borderRadius: '6.25rem',
              backgroundColor: '#FBFCFE',
              padding: '0.5rem',
              marginBottom: '1rem'
            }}
          >
            <SvgWrapper
              icon={FileLocked}
              width="1.25rem"
              height="1.25rem"
              styleOverrides={{ opacity: 0.8 }}
              color="#2393C4"
            />
          </Box>
        </Stack>

        <Stack
          width="30rem"
          direction="row"
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Stack
            direction="column"
            display="flex"
            justifyContent="space-between"
          >
            <Typography variant="bodyLargeSemibold" color="#475467">
              {productReceipt.holders[1].name}
            </Typography>

            <Stack
              width="auto"
              direction="row"
              display="flex"
              justifyContent="space-between"
            >
              <Typography variant="bodyMediumRegular" color="#98A2B3">
                {productReceipt.product.name}
              </Typography>

              <Divider
                orientation="vertical"
                variant="middle"
                sx={{
                  borderRadius: '0.125rem',
                  borderLeft: '0.0625rem solid #EAECF0',
                  borderRight: '0.0625rem solid #EAECF0',
                  margin: '0rem 0.5rem',
                  height: '1.187rem'
                }}
              />

              <Typography variant="bodyMediumRegular" color="#98A2B3">
                Quantity/{' '}
                {formattedNumber(productReceipt.total_quantity, false)}{' '}
                {productReceipt.product.unit}
              </Typography>
            </Stack>
          </Stack>

          <Typography
            variant="h7Semibold"
            color="#475467"
            paddingBottom="0.25rem"
          >
            {formattedNumber(
              productReceipt.quoted_price * productReceipt.total_quantity,
              true
            )}
          </Typography>
        </Stack>
      </Paper>
    </Box>
  );
};

export default ProductReceiptDetails;
