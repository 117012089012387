import React from 'react';
import { theme } from 'themes/theme';
import { Box, Typography, Paper, Stack } from '@mui/material';
import { Button } from 'components/button';
import { FormInput } from 'components/form';
import { SubmitHandler, useForm } from 'react-hook-form';

type FormValues = {
  street_address: string;
  postal_code: string;
  state: string;
  country: string;
  city: string;
  industry: string;
};

const BusinessAddress = () => {
  const { handleSubmit, control } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      street_address: '',
      postal_code: '',
      state: '',
      country: '',
      city: ''
    }
  });

  const onSubmit: SubmitHandler<FormValues> = () => {
    // TODO
    // call update branch endpoint with branch ID
  };

  return (
    <Paper
      elevation={0}
      sx={{
        backgroundColor: `${theme.palette.common.white}`,
        border: `0.063rem solid ${theme.palette.common.cardBorder}`,
        borderRadius: '0.75rem',
        gap: '1.5rem',
        padding: '1.5rem',
        width: '100%'
      }}
    >
      <Box width="inherit">
        <Box
          width="inherit"
          sx={{
            alignItems: 'flex-start',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Typography
            variant="bodyLargeSemibold"
            sx={{
              color: `${theme.palette.common.heading}`
            }}
          >
            Business Address
          </Typography>
          <Typography
            variant="bodyMediumRegular"
            sx={{
              color: `${theme.palette.common.labelColor}`
            }}
          >
            Information on your business location
          </Typography>
        </Box>

        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Box width="inherit" sx={{ mt: '1.5rem' }}>
            <FormInput<FormValues>
              control={control}
              name="street_address"
              label="STREET ADDRESS"
            />

            <Stack width="100%" direction="column">
              <Stack width="100%" gap=".75rem" direction="row">
                <FormInput<FormValues>
                  control={control}
                  name="postal_code"
                  label="POSTAL CODE"
                />

                <FormInput<FormValues>
                  control={control}
                  name="industry"
                  label="INDUSTRY"
                />
              </Stack>
              <Stack width="100%" gap=".75rem" direction="row">
                <FormInput<FormValues>
                  control={control}
                  name="state"
                  label="STATE"
                />
                <FormInput<FormValues>
                  control={control}
                  name="city"
                  label="CITY/TOWN"
                />
              </Stack>
            </Stack>
          </Box>

          <Stack direction="row" display="flex" justifyContent="flex-end">
            <Button
              color="grey"
              transparent
              size="md"
              text="Cancel"
              styleOverrides={{
                padding: '0.5rem 0.75rem'
              }}
            />

            <Button
              color="primary"
              size="md"
              text="Save Changes"
              styleOverrides={{
                padding: '0.5rem 0.75rem',
                ml: '1rem'
              }}
              submit
            />
          </Stack>
        </form>
      </Box>
    </Paper>
  );
};

export default BusinessAddress;
