import React from 'react';
import { Box, Paper, Stack, Typography } from '@mui/material';
import { SvgWrapper } from 'components/svg';
import { Chip } from 'components/chip';
import { millisecsToMinsHr } from 'utilities/helperFunc/timeDifference';

type AnalyticsCardData = {
  title: string;
  image: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string;
    }
  >;
  time?: number;
  iconFill: string;
  iconStroke?: string;
};

const IndicatorCard = ({
  title,
  time,
  image,
  iconFill,
  iconStroke
}: AnalyticsCardData) => {
  let hour = null;
  if (time) {
    hour = millisecsToMinsHr(time).includes('hr')
      ? parseInt(millisecsToMinsHr(time).split(' ')[0])
      : 0;
  }

  return (
    <Paper
      elevation={0}
      sx={{
        display: 'flex',
        padding: '1rem 0rem',
        alignItems: 'center',
        backgroundColor: '#FFF',
        border: 'none'
      }}
    >
      <Stack
        direction="row"
        gap=".5rem"
        sx={{
          height: '100%',
          width: 'auto'
        }}
      >
        <Box
          sx={{
            width: '2.25rem',
            height: '2.25rem',
            borderRadius: '.75rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#F9FAFE'
          }}
        >
          <SvgWrapper
            icon={image}
            width="1rem"
            height="1rem"
            styleOverrides={{ fill: iconFill, stroke: iconStroke }}
          />
        </Box>

        <Stack
          direction="column"
          display="flex"
          justifyContent="space-between"
          sx={{
            height: '100%'
          }}
        >
          <Stack
            direction="row"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              gap: {
                md: '1rem',
                xl: '1.25rem'
              }
            }}
          >
            <Typography
              variant="bodyMediumMedium"
              color="#667085"
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                width: hour !== null ? '80%' : 'auto',
                letterSpacing: -0.9
              }}
            >
              {title}
            </Typography>
            {hour !== null && (
              <Chip
                label={hour >= 3 ? 'Late' : 'OnTime'}
                size="xs"
                color={hour >= 3 ? 'error' : 'success'}
              />
            )}
          </Stack>

          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <Typography variant="h7Semibold" color="#475467">
              {time ? millisecsToMinsHr(time) : 'Nil'}
            </Typography>
            <Typography variant="bodySmallMedium" color="#98A2B3">
              /vs 3hrs
            </Typography>
          </Box>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default IndicatorCard;
