import React, { useState } from 'react';
import { Box, Stack } from '@mui/material';
import { ReactComponent as PlusIcon } from 'assets/svg/plus.svg';
import ProductReceiptWorkflowCard from './workflowCard';
import { ProductReceiptApiData } from 'services/types/productReceipt';
import { WorkflowApiData } from 'services/types/workflow';
import { WorkflowViewer } from 'components/workflows';
import NotFound from '../notFound';
import { useStartEndorsementWorkflowMutation } from 'services/api/productReceiptApi';
import { useAppSelector } from 'services/hook';
import CreditRiskCheck from '../creditRiskCheck';
import ShowInfo from 'components/info/showInfo';
import _ from 'lodash';
import { APIErrorData } from 'services/types/error';
import { OrganizationType } from 'services/enums/organization';
import { WorkflowTargetObject } from 'services/enums/workflows';
import { useGetPermissionsQuery } from 'services/api/permissionsApi';
import { PermissionType } from 'services/enums/permissions';

const ProductReceiptWorkflows = ({
  productReceipt
}: {
  productReceipt: ProductReceiptApiData;
}) => {
  const [currentWorkflow, setCurrentWorkflow] =
    useState<WorkflowApiData | null>(null);
  const [startEndorsementWorkflow, { isLoading: isLoadingEndorsement }] =
    useStartEndorsementWorkflowMutation();
  const { organizationType } = useAppSelector(state => state.auth);
  const [checkCreditRisk, setCheckCreditRisk] = useState(false);
  const { data: permissions } = useGetPermissionsQuery({});

  const onClickWorkflow = (workflowUseCase: 'endorsement' | 'assignment') => {
    if (
      workflowUseCase === 'endorsement' &&
      productReceipt.endorsement_workflow
    ) {
      setCurrentWorkflow(productReceipt.endorsement_workflow);
    } else if (
      workflowUseCase === 'assignment' &&
      productReceipt.branch_assignment_workflow
    ) {
      setCurrentWorkflow(productReceipt.branch_assignment_workflow);
    }
  };

  const startEndorsementWorkflowFn = () => {
    if (
      !permissions ||
      !permissions.includes(
        PermissionType.PRODUCT_RECEIPT_START_ENDORSEMENT_WORKFLOW
      ) ||
      !permissions.includes(
        PermissionType.PRODUCT_RECEIPT_START_BRANCH_ASSIGNMENT_WORKFLOW
      )
    ) {
      ShowInfo({
        message: 'Endorsement Workflow Failed to Start',
        subText: 'You do not have permissions to do this!',
        type: 'error',
        componentType: 'toast',
        hideProgressBar: false
      });
      return;
    }

    startEndorsementWorkflow({
      productReceiptId: productReceipt.product_receipt_id
    })
      .unwrap()
      .then(() => {
        ShowInfo({
          message: 'Endorsement Workflow Started!',
          type: 'success',
          componentType: 'toast',
          hideProgressBar: false
        });
      })
      .catch((error: APIErrorData) => {
        ShowInfo({
          message: 'Workflow Failed to Start',
          subText: `${_.startCase(error.data.detail ?? '')}`,
          type: 'error',
          componentType: 'toast',
          hideProgressBar: false
        });
      });
  };

  if (
    productReceipt.assigned_branch === null &&
    productReceipt.endorsement_workflow === null
  ) {
    return checkCreditRisk ? (
      <CreditRiskCheck
        onClick={() => startEndorsementWorkflowFn()}
        loading={isLoadingEndorsement}
      />
    ) : (
      <NotFound
        title="No Workflow"
        subtitle={`Start workflow to ${organizationType === OrganizationType.MERCHANT ? 'issue' : 'endorse'} this product receipt`}
        btnIcon={PlusIcon}
        btnIconPosition="start"
        btnText="Start Workflow"
        onClick={() => {
          if (organizationType === OrganizationType.LENDER) {
            setCheckCreditRisk(true);
          } else {
            startEndorsementWorkflowFn();
          }
        }}
        loading={isLoadingEndorsement}
      />
    );
  } else if (
    productReceipt.endorsement_workflow !== null &&
    productReceipt.endorsements.length !== 2
  ) {
    return (
      <Box
        sx={{
          position: 'relative',
          height: {
            lg: '60svh',
            xl: '75svh'
          },
          width: 'inherit'
        }}
      >
        <WorkflowViewer
          workflow={productReceipt.endorsement_workflow}
          workflowTargetObject={WorkflowTargetObject.PRODUCT_RECEIPT}
          workflowTargetObjectId={productReceipt.product_receipt_id}
          invalidateTag="productReceipts"
          invalidateTagId={productReceipt.product_receipt_id}
        />
      </Box>
    );
  } else if (
    productReceipt.endorsement_workflow !== null &&
    (productReceipt.endorsements.length === 0 ||
      (organizationType !== OrganizationType.MERCHANT &&
        productReceipt.endorsements.length < 2))
  ) {
    return (
      <Box
        sx={{
          position: 'relative',
          height: {
            lg: '60svh',
            xl: '75svh'
          },
          width: 'inherit'
        }}
      >
        <WorkflowViewer
          workflow={productReceipt.endorsement_workflow}
          workflowTargetObject={WorkflowTargetObject.PRODUCT_RECEIPT}
          workflowTargetObjectId={productReceipt.product_receipt_id}
          invalidateTag="productReceipts"
          invalidateTagId={productReceipt.product_receipt_id}
        />
      </Box>
    );
  } else {
    return (
      <>
        {!currentWorkflow ? (
          <Stack
            sx={{
              width: 'inherit',
              maxWidth: '80rem',
              margin: '0 auto'
            }}
            direction="column"
            gap=".5rem"
            justifyContent="space-between"
            alignItems="center"
          >
            {productReceipt.endorsement_workflow && (
              <ProductReceiptWorkflowCard
                onClickWorkflow={() => onClickWorkflow('endorsement')}
                workflow={productReceipt.endorsement_workflow}
              />
            )}
          </Stack>
        ) : (
          <Box
            sx={{
              position: 'relative',
              height: {
                lg: '60svh',
                xl: '75svh'
              },
              width: 'inherit'
            }}
          >
            <WorkflowViewer
              workflow={currentWorkflow}
              workflowTargetObject={WorkflowTargetObject.PRODUCT_RECEIPT}
              workflowTargetObjectId={productReceipt.product_receipt_id}
              invalidateTag="productReceipts"
              invalidateTagId={productReceipt.product_receipt_id}
            />
          </Box>
        )}
      </>
    );
  }
};

export default ProductReceiptWorkflows;
