import React, { ReactNode } from 'react';
import { Paper, Box, Typography, Stack, Avatar } from '@mui/material';
import { theme } from 'themes/theme';
import { useNavigate } from 'react-router-dom';
import { Button } from 'components/button';

interface ModalProps {
  text: string;
  subText: ReactNode | string;
  src?: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  btnText: string;
  disabled: boolean;
  skip?: string;
}

const AuthModal = ({
  text,
  subText,
  src,
  onClick,
  btnText,
  disabled,
  skip
}: ModalProps) => {
  const navigate = useNavigate();

  return (
    <Paper
      elevation={0}
      sx={{
        backgroundColor: `${theme.palette.common.card}`,
        border: `1.5px solid ${theme.palette.common.cardBorder}`,
        borderRadius: '.75rem',
        width: '30rem',
        margin: {
          md: '0 auto'
        },
        padding: '2.5rem 1.5rem',
        marginTop: {
          md: '13rem'
        },
        marginBottom: {
          md: '20rem'
        }
      }}
    >
      <Box sx={{ width: { lg: '27rem', textAlign: 'left' } }}>
        <Avatar src={src} />

        <Box width="inherit" sx={{ mt: '1rem' }}>
          <Typography
            variant="h5"
            sx={{
              color: `${theme.palette.common.subheading}`,
              mb: '.25rem'
            }}
          >
            {text}
          </Typography>
          <> {subText}</>
        </Box>

        <Stack width="inherit" direction="column" sx={{ mt: '1.5rem' }}>
          <Button
            color="primary"
            size="lg"
            text={btnText}
            styleOverrides={{
              padding: '0.625rem 1rem',
              width: '100%'
            }}
            onClick={onClick}
            disabled={disabled}
          />

          {skip ? (
            <Button
              color="primary"
              size="lg"
              text={skip}
              styleOverrides={{
                padding: '0.625rem 1rem',
                width: '100%'
              }}
              onClick={() => navigate('/register/organization/form')}
            />
          ) : null}
        </Stack>
      </Box>
    </Paper>
  );
};

export default AuthModal;
