import React from 'react';
import { Box, Typography, Stack } from '@mui/material';
import { theme } from 'themes/theme';
import { Button } from 'components/button';

const DevHomePage = () => {
  return (
    <Box>
      <Stack gap=".25rem" width="inherit" sx={{ textAlign: 'center' }}>
        <Typography
          variant="bodyMediumSemibold"
          sx={{
            color: `${theme.palette.common.subheading}`,
            textTransform: 'uppercase'
          }}
        >
          Welcome To The Dev Home
        </Typography>
      </Stack>
      <Box sx={{ marginTop: '1.5rem', paddingY: '0.5rem' }}>
        <Typography
          variant="bodyMediumSemibold"
          color="#475467"
          sx={{ marginBottom: '0.5rem' }}
        >
          Regular Buttons
        </Typography>
        <Stack gap=".5rem" direction="row">
          <Button color="primary" size="md" text="Primary Button" />
          <Button color="grey" size="md" text="Grey Button" />
          <Button color="success" size="md" text="Success Button" />
          <Button color="warning" size="md" text="Warning Button" />
          <Button color="error" size="md" text="Error Button" />
        </Stack>
      </Box>
      <Box sx={{ marginTop: '1.5rem', paddingY: '0.5rem' }}>
        <Typography
          variant="bodyMediumSemibold"
          color="#475467"
          sx={{ marginBottom: '0.5rem' }}
        >
          Outline Buttons
        </Typography>
        <Stack gap=".5rem" direction="row">
          <Button
            color="primary"
            outline
            size="md"
            text="Primary Outline Button"
          />
          <Button color="grey" outline size="md" text="Grey Outline Button" />
          <Button
            color="success"
            outline
            size="md"
            text="Success Outline Button"
          />
          <Button
            color="warning"
            outline
            size="md"
            text="Warning Outline Button"
          />
          <Button color="error" outline size="md" text="Error Outline Button" />
        </Stack>
      </Box>
      <Box sx={{ marginTop: '1.5rem', paddingY: '0.5rem' }}>
        <Typography
          variant="bodyMediumSemibold"
          color="#475467"
          sx={{ marginBottom: '0.5rem' }}
        >
          Transparent Buttons
        </Typography>
        <Stack gap=".5rem" direction="row">
          <Button
            color="primary"
            transparent
            size="md"
            text="Primary Transparent Button"
          />
          <Button
            color="grey"
            transparent
            size="md"
            text="Grey Transparent Button"
          />
          <Button
            color="success"
            transparent
            size="md"
            text="Success Transparent Button"
          />
          <Button
            color="warning"
            transparent
            size="md"
            text="Warning Transparent Button"
          />
          <Button
            color="error"
            transparent
            size="md"
            text="Error Transparent Button"
          />
        </Stack>
      </Box>
      <Box sx={{ marginTop: '1.5rem', paddingY: '0.5rem' }}>
        <Typography
          variant="bodyMediumSemibold"
          color="#475467"
          sx={{ marginBottom: '0.5rem' }}
        >
          Disabled Buttons
        </Typography>
        <Stack gap=".5rem" direction="row" sx={{ marginBottom: '0.5rem' }}>
          <Button
            color="primary"
            disabled
            size="md"
            text="Primary Disabled Button"
          />
          <Button color="grey" disabled size="md" text="Grey Disabled Button" />
          <Button
            color="success"
            disabled
            size="md"
            text="Success Disabled Button"
          />
          <Button
            color="warning"
            disabled
            size="md"
            text="Warning Disabled Button"
          />
          <Button
            color="error"
            disabled
            size="md"
            text="Error Disabled Button"
          />
        </Stack>
        <Stack gap=".5rem" direction="row" sx={{ marginBottom: '0.5rem' }}>
          <Button
            color="primary"
            disabled
            outline
            size="md"
            text="Primary Disabled Button"
          />
          <Button
            color="grey"
            disabled
            outline
            size="md"
            text="Grey Disabled Button"
          />
          <Button
            color="success"
            disabled
            outline
            size="md"
            text="Success Disabled Button"
          />
          <Button
            color="warning"
            disabled
            outline
            size="md"
            text="Warning Disabled Button"
          />
          <Button
            color="error"
            disabled
            outline
            size="md"
            text="Error Disabled Button"
          />
        </Stack>
        <Stack gap=".5rem" direction="row">
          <Button
            color="primary"
            disabled
            transparent
            size="md"
            text="Primary Disabled Button"
          />
          <Button
            color="grey"
            disabled
            transparent
            size="md"
            text="Grey Disabled Button"
          />
          <Button
            color="success"
            disabled
            transparent
            size="md"
            text="Success Disabled Button"
          />
          <Button
            color="warning"
            disabled
            transparent
            size="md"
            text="Warning Disabled Button"
          />
          <Button
            color="error"
            disabled
            transparent
            size="md"
            text="Error Disabled Button"
          />
        </Stack>
      </Box>
      <Box sx={{ marginTop: '1.5rem', paddingY: '0.5rem' }}>
        <Typography
          variant="bodyMediumSemibold"
          color="#475467"
          sx={{ marginBottom: '0.5rem' }}
        >
          Loading Buttons
        </Typography>
        <Stack gap=".5rem" direction="row" sx={{ marginBottom: '0.5rem' }}>
          <Button
            color="primary"
            size="xs"
            text="Loading Button xs"
            loadingIcon
          />
          <Button color="grey" size="sm" text="Loading Button sm" loadingIcon />
          <Button
            color="grey"
            transparent
            size="md"
            text="Loading Button md"
            loadingIcon
          />
          <Button
            color="warning"
            outline
            size="lg"
            text="Loading Button lg"
            loadingIcon
          />
        </Stack>
      </Box>
    </Box>
  );
};

export default DevHomePage;
